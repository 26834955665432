export default async function updateUserProfile(userProfile, user, data) {
  const createTargetUrl = `${process.env.CMS_URL}/user-profiles/${userProfile.id}`;

  let updatedData = data;
  if (!userProfile.PictureURL && user?.photoURL) {
    updatedData["PictureURL"] = user.photoURL;
  }

  let resData;
  const token = await user.getIdToken();

  try {
    const res = await fetch(createTargetUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...updatedData,
      }),
    });
    resData = await res.json();
  } catch (error) {
    console.log("error updating user profile");
    console.log(error);
  }

  return resData;
}
