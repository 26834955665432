export default function formatDate(stringDate) {
  if (stringDate) {
    const date = new Date(stringDate);

    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const year = date.getFullYear();

    const formattedDate = month + "/" + day + "/" + year;
    return formattedDate;
  } else {
    return "";
  }
}
