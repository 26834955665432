import * as React from "react";
//
// @mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import formatMoney from "lib/formatMoney";
import { useTheme } from "@mui/styles";

export default function ListingCard({ listing }) {
  const theme = useTheme();

  const linkURL = `${
    process.env.deployment === "dev"
      ? "http://localhost:3000"
      : process.env.absoluteUrl
  }/listings/${listing?.UrlAddress}`;

  let imgLoc, imgURL;

  if (listing?.Photos && listing?.Photos[0]) {
    const imgData = listing.Photos[0];

    if (imgData.formats) {
      imgLoc = `${imgData.formats?.small?.url}`;
    } else {
      imgLoc = `${imgData.url}`;
    }
    imgURL = `${process.env.CMS_URL}${imgLoc}`;
  } else {
    imgURL = `${process.env.absoluteUrl}/omlogo_placeholder.png`;
  }

  return (
    <Card
      sx={{
        display: "flex",
        flex: "auto",
      }}
    >
      <CardActionArea
        target="_blank"
        href={linkURL}
        sx={{
          margin: "auto",
          textDecoration: "none !important",
          color: "inherit !important",
          "&:hover": { color: `${theme.palette.primary.main} !important` },
        }}
      >
        <CardMedia
          component="img"
          height="300"
          image={imgURL}
          alt="Curb-appeal photo of the property"
        />
        <CardContent
          sx={{
            padding: theme.spacing(1, 2, 0, 2),
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {listing?.Address}
          </Typography>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              height: theme.spacing(8),
            }}
            variant="body2"
            color="text.primary"
          >
            {listing?.Description}
          </Typography>
          <Typography
            sx={{
              margin: theme.spacing(2, 0, 0, 0),
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              justifyContent: "space-between",
            }}
            variant="body3"
            color="text.primary"
          >
            <p>{`${listing?.Views} ${
              listing?.Views > 1 ? "Views" : "View"
            }`}</p>
            <p>{`$${formatMoney(listing?.ListPrice, 0)}`}</p>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
