import React, { useState, useEffect, useRef } from "react";
import { useStore } from "outstated";
import { AppStore } from "pages/_app";
// utils
import updateUserProfile from "lib/updateUserProfile";
// mui
import { useRouter } from "next/router";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import formatDate from "lib/formatDate";
import loanStatuses from "lib/loanStatuses";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Authenticator from "components/Authenticator";

export default function Referrals({ user, userProfile }) {
  const theme = useTheme();
  const router = useRouter();
  const { snackbarData, setSnackbarData, setIsAuthModalOpen } =
    useStore(AppStore);

  const [userReferralURL, setUserReferralURL] = useState(null);
  const [refTagsGenerated, setRefTagsGenerated] = useState(null);
  const [userUniqRef, setUserUniqRef] = useState(null);
  const [refLoans, setRefLoans] = useState([]);

  useEffect(() => {
    if (userProfile && Object.keys(userProfile).length > 0) {
      if (!userProfile?.RefTags || userProfile?.RefTags?.length === 0) {
        setRefTagsGenerated(false);
      }
      if (userProfile?.RefTags && userProfile?.RefTags?.length > 0) {
        setRefTagsGenerated(true);
        const uniqRef = userProfile?.RefTags[0];
        setUserUniqRef(uniqRef);
        const userReferralURL = `https://www.offermarket.us/loans?ref=${uniqRef}`;
        setUserReferralURL(userReferralURL);
      }

      if (userProfile?.RefLoans && userProfile?.RefLoans?.length > 0) {
        setRefLoans(userProfile?.RefLoans.sort((a, b) => b.id - a.id));
      }
    }
  }, [userProfile]);

  const generateUniqRef = () => {
    // generate a tag for the user

    const uniqRef = Math.random().toString(36).slice(2);
    const userReferralURL = `https://www.offermarket.us/loans?ref=${uniqRef}`;
    setUserReferralURL(userReferralURL);
    setRefTagsGenerated(true);

    (async () => {
      // update user profile with it
      // ref tags are empty per the if above
      await updateUserProfile(userProfile, user, {
        RefTags: [uniqRef],
      });
    })();
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
      elevation={3}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing(3, "auto", 0, 2),
          fontSize: theme.spacing(3),
          color: "black !important",
        }}
      >
        <Typography as="h2" sx={{ fontSize: theme.spacing(4) }}>
          Loan Referrals
        </Typography>
        <Box>Give $250, get $250</Box>
      </Box>
      <Box
        sx={{
          margin: theme.spacing(2),
        }}
      >
        <Alert
          variant="outlined"
          icon={<Box>1</Box>}
          sx={{
            width: "100% !important",
            "& .MuiAlert-message": {
              width: "100% !important",
            },
            display: "flex",
            margin: theme.spacing(1, 0),
            color: "black !important",
          }}
          color={"info"}
        >
          {userProfile &&
          Object.keys(userProfile).length > 0 &&
          refTagsGenerated === null ? (
            <CircularProgress />
          ) : refTagsGenerated ? (
            <>
              <Box sx={{ textAlign: "left" }}>
                Copy & share your referral link{" "}
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <TextField
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    margin: theme.spacing(2, 0),
                  }}
                  label={"Referral URL"}
                  variant="outlined"
                  value={userReferralURL || ""}
                />
                <Button
                  variant={snackbarData.message ? "outlined" : "contained"}
                  onClick={() => {
                    navigator.clipboard.writeText(userReferralURL).then(() => {
                      setSnackbarData({
                        message: "Copied",
                        options: {
                          variant: "success",
                          autoHideDuration: 5000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        },
                      });
                    });
                  }}
                >
                  {snackbarData.message === "Copied!" ? "Copied!" : "Copy"}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ textAlign: "left" }}>Generate your referral link </Box>
              <Box sx={{ textAlign: "right" }}>
                <Button
                  sx={{ margin: theme.spacing(1, 0) }}
                  variant={snackbarData.message ? "outlined" : "contained"}
                  onClick={() => {
                    if (Object.keys(userProfile).length === 0) {
                      setIsAuthModalOpen(true);
                    } else {
                      setSnackbarData({
                        message: "Link generated",
                        options: {
                          variant: "success",
                          autoHideDuration: 5000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        },
                      });

                      generateUniqRef();
                    }
                  }}
                >
                  {snackbarData.message ? "Generated" : "Create"}
                </Button>
              </Box>
            </>
          )}
        </Alert>
        <Alert
          variant="outlined"
          icon={<Box>2</Box>}
          sx={{
            width: "100% !important",
            "& .MuiAlert-message": {
              width: "100% !important",
            },
            textAlign: "left",
            margin: theme.spacing(1, 0),
            color: "black !important",
            "& a": {
              color: "#0000FF",
            },
          }}
          color={"info"}
        >
          Every new client that uses your <i>link</i> and closes with us counts.{" "}
          <Box sx={{ margin: theme.spacing(1, 0) }}>
            <a
              target="_blank"
              href={"https://www.offermarket.us/referral-program"}
            >
              Referral program information
            </a>
          </Box>
        </Alert>
        <Alert
          variant="outlined"
          icon={<Box>3</Box>}
          sx={{
            width: "100% !important",
            "& .MuiAlert-message": {
              width: "100% !important",
            },
            textAlign: "left",
            margin: theme.spacing(1, 0),
            color: "black !important",
          }}
          color={"info"}
        >
          <Box sx={{ width: "100%", display: "flex" }}>
            <Box> Track referrals & earn {"  "}</Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              margin: theme.spacing(3, 0, 0, 0),
              display: "flex",
            }}
          >
            {refLoans && refLoans.length > 0 ? (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {refLoans.map((refLoan, index) => {
                  return (
                    <Alert
                      key={`referrals_alert_${index}`}
                      severity={refLoan.leadRewardPaid ? "success" : "info"}
                      sx={{
                        margin: theme.spacing(1, 0),
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>Loan created:</Box>
                        <Box>
                          {refLoan.created_at && formatDate(refLoan.created_at)}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box> Percent processed:</Box>
                        <Box>{`  ${
                          ((loanStatuses.indexOf(refLoan.Status) > 5 &&
                            refLoan.Status !== "deleted") ||
                          refLoan.leadRewardPaid
                            ? "100%"
                            : refLoan.cumulativePercentCompleted &&
                              `${refLoan.cumulativePercentCompleted}%`) || `0%`
                        }`}</Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>Status:</Box>
                        <Box>
                          {refLoan.leadRewardPaid
                            ? "REWARD CLAIMED"
                            : refLoan.Status && refLoan.Status.toUpperCase()}
                        </Box>
                      </Box>

                      {/* if above 'approved and above' */}

                      {loanStatuses.indexOf(refLoan.Status) > 5 &&
                        ![
                          "hold",
                          "retracted",
                          "declined",
                          "deleted",
                          "referred",
                        ].includes(refLoan.Status) && (
                          <>
                            <Box
                              sx={{
                                borderRadius: theme.spacing(0.5),
                                border: `1px solid ${theme.palette.success.light}`,
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                margin: theme.spacing(2, 0),
                                padding: theme.spacing(1),
                              }}
                            >
                              <Box
                                sx={{
                                  color: `${theme.palette.success.dark} !important`,
                                  margin: theme.spacing("auto", 0),
                                }}
                              >
                                Loan funded
                              </Box>
                              <Box>
                                <Button
                                  sx={{
                                    textDecoration: "none !important",
                                    color: "white !important",
                                    "&:hover": {
                                      backgroundColor: `${theme.palette.success.dark} !important`,
                                    },
                                  }}
                                  variant="contained"
                                  color="success"
                                  href={`mailto:hello@offermarket.us?subject=Referral reward claim&body=Please send me my $250 referral reward. My referral code is ${userUniqRef} and the funded loan was created on ${refLoan.created_at}`}
                                >
                                  Claim your $250
                                </Button>
                              </Box>
                            </Box>
                          </>
                        )}
                    </Alert>
                  );
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  margin: theme.spacing(0, 0, 0, "auto"),
                }}
              >
                <Button
                  onClick={() => {
                    window.open(
                      `https://www.offermarket.us/my/loans`,
                      "_blank"
                    );
                  }}
                  variant="contained"
                >
                  My Loans
                </Button>
              </Box>
            )}
          </Box>
          {userReferralURL && (
            <Box sx={{ margin: theme.spacing(1, 0) }}>
              <Box as={"a"} href={userReferralURL || ""}>
                Get a quote using your own link to see how referral tracking
                works
              </Box>
            </Box>
          )}
        </Alert>
      </Box>
    </Paper>
  );
}
